import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import ReactPlayer from 'react-player';
//import Poster from "../images/poster.png";

function HowToPage() {
  return (
    <Layout>
      <SEO
        keywords={[`how to use`]}
        title={'How to use'}
      />

      <section className="container mx-auto px-8 sm:px-32 lg:px-32">
        <h1 className="text-center font-bold text-5xl mt-20">How to use</h1>
        <div className="flex justify-center mt-20 mb-10 sm:mb-20 ">
          {
            /*
            <video preload="auto" src={"https://www.youtube.com/embed/W81dlS5G8Gs"} className={"hidden-sm"} autoPlay muted loop />
            */
          }
          <div className="video-container">
            <ReactPlayer
              className={"video"}
              url='https://www.youtube.com/watch?v=LmoyfGFlPGE'
              width='100%'
              height='100%'
              controls={true}
            />
          </div>


        </div>



      </section>
    </Layout>
  );
}

export default HowToPage;
